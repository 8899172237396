@import 'styles/main';

.content{
    background-color: $green;
    margin-top: $spacing-m;
    @include media-breakpoint-up(xl) {
        border-radius: $spacing-xs;
    }
}

.title{
    color: $white;
    margin-top: $spacing-s;
    margin-bottom: $spacing-xs;
    align-items: center;
}

.intro{
    color: $white;
    padding-bottom: $spacing-s;
}

.text{
    color: $green;
    text-align: center;
    padding-top: $spacing-s;
}

.flex{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
