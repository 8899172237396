@import 'styles/main';

.most-viewed-content {
  :global(li > div) {
    width: $spacing-l;
    min-width: $spacing-l;
    align-content: center;
  }
  &:global(.normal .new-bold) {
    line-height: 26px;
  }
  &:not(:global(.compact)) {
    @include media-breakpoint-up(m) {
      :global(ol > *) {
        flex: 1 1 calc(50% - #{$spacing-basis});
        max-width: calc(50% - #{$spacing-basis});
      }
    }
  }
}
.wrapper {
  background-color: $grey-cloud-ultralight;
}
