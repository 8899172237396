@import 'styles/main';

.journal-news-headlines{
  padding: $spacing-m 20px 10px 20px;

  .no-space {
    span {
      line-height: unset;
    }
  }

  @include media-breakpoint-up(m) {
    padding-bottom: $spacing-m;
  }
}

.newsWrapper{
  @include media-breakpoint-up(m) {
    background: $grey-cloud-ultralight;
  }
}



