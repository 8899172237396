@import 'styles/main';

.free-field {
  max-width: calc(#{map-get($breakpoints, l)} + #{$spacing-m} * 2);
  width: 100%;
  margin: $spacing-s auto;

  figure {
    flex: 1 1 327px;
    margin-bottom: $spacing-m;
    padding: 0 $spacing-m;

    img {
      width: 100%;
      min-width: 100%;
      height: 70%;
      object-fit: cover;
    }

    :global(.plc-img) {
      height: max-content;
    }
  }

  .content {
    flex: 1 1 325px;
    align-self: center;
    padding: 0 $spacing-m;
    height: max-content;

    h2 {
      margin-bottom: $spacing-s;
    }
    p {
      margin-bottom: $spacing-m;
    }
  }

  @include media-breakpoint-up(m) {
    margin: $spacing-basis auto $spacing-m auto;

    figure {
      max-width: calc(497px + #{$spacing-m} * 2);
      max-height: 497px;
    }

    .content {
      margin-left: $spacing-xxl - $spacing-m;
      padding: 0;

      :global(.wrapper) {
        max-width: 325px;
      }
    }
  }

  @media (min-width: 652px) {
    min-height: max-content;
  }
}
