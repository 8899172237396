@import 'styles/main';

.root {
  & {
    @include media-breakpoint-up(m) {
      :global(ol > *) {
        flex: 1 1 calc(50% - #{$spacing-basis});
      }
    }
  }
}
.ol-analyse {
  list-style-type: none;
  li div {
    width: 100%;
  }
}
.analyse-flex-child-moyen {
  flex: 45%;
  padding: 0 15px;
  @media (min-width: 768px) and (max-width: 854px) {
    h2 {
      margin-bottom: 5px;
    }
  }
}

.analyse-margin-top {
  margin-top: 0px;
}
@media (max-width: 900px) {
  .analyse-flex-child {
    flex: 100%;
  }
  .analyse-margin-top {
    margin-top: 20px;
  }
}