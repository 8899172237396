@import 'styles/main';

.agenda-root {
  & {
    @include media-breakpoint-up(m) {
      :global(ul > *) {
        flex: 1 1 calc(50% - #{$spacing-basis});
      }
    }

    @include media-breakpoint-down(m) {
      margin-top: 8%;
    }
  }
}
.margin{
  @include media-breakpoint-down(m) {
    margin-top: 8%;
  }
}
.marginLeft{
  margin-left: 2%;
}